import store from './../../store'
import { GoogleId } from './constants/partnerName'

const GoogleLinkBadges = {
  empty: {
    text: '',
    variant: ''
  },
  incomplete: {
    text: 'incomplete',
    variant: 'warn'
  },
  linked: {
    text: 'linked',
    variant: 'success'
  },
  unlinked: {
    text: 'unlinked',
    variant: 'info'
  }
}

const IsGoogleLinked = {
  Content: (link) => {
    return link?.linkedAccountStatus?.accessGranted && link?.linkedAccountStatus?.websiteClaimed
  },
  MyBusiness: (link) => {
    return link?.linkedAccountStatus?.verified
  },
}

export const DefaultGoogleLinkStatus = {
  status: null,
  hasGoogle: false,
  badge: GoogleLinkBadges.empty
}

export const googleLinkStatus = (linkType, account) => {
  const link = store.getters?.[`google${linkType}LinkStatus`]?.[account?.accountPlanId] || null
  const hasGoogle = account?.subAccounts?.find(p => p?.partnerId === GoogleId) !== undefined
  let badge = GoogleLinkBadges.empty

  if (null === link) {
    if (hasGoogle) {
      badge = GoogleLinkBadges.unlinked
    }
  } else {
    if (typeof IsGoogleLinked[linkType] === 'function') {
      badge = IsGoogleLinked[linkType](link) ? GoogleLinkBadges.linked : GoogleLinkBadges.incomplete
    }
  }

  return { status: link, hasGoogle, badge }
}
